import { useStaticQuery, graphql } from 'gatsby';
import React from 'react';
import { ReactComponent as Download } from '../components/document-table/assets/download.svg';

import Button from '../components/button';
import Link from '../components/link';
import styles from '../components/document-table/document-table.module.scss';

import cleanUpDocExcelFileTypes from "../utils/cleanUpFileNameExtensions"

export const useDocumentsData = () => {
  const { allMediaDocument, site } = useStaticQuery(
    graphql`
      query DocumentsData {
        allMediaDocument {
          nodes {
            drupal_id
            name
            field_status
            field_reference {
              value
            }
            created(formatString: "M/DD/YY")
            createdShort: created(formatString: "M/DD")
            id
            path {
              alias
            }
            revision_created(formatString: "M/DD/YY")
            path {
              alias
            }
            relationships {
              field_media_file {
                filemime
                filename
                drupal_id
                uri {
                  url
                }
              }
              uid {
                field_first_name
                field_last_name
                name
                drupal_id
              }
              field_reference {
                field_document_type_code
              }
              field_department {
                name
                drupal_id
              }
            }
          }
        }
        site {
          siteMetadata {
            drupalSiteURL
          }
        }
      }
    `
  );

  // Clean up the field mapping so it's easier to reference them
  // in the component.
  const documents = allMediaDocument.nodes.map((item) => {
    const firstName = item?.relationships?.uid?.field_first_name || '';
    const lastName = item?.relationships?.uid?.field_last_name || '';

    // eslint-disable-next-line camelcase
    const firstInitial = firstName
      .charAt(0)
      .toUpperCase();
    const lastInitial = lastName
      .charAt(0)
      .toUpperCase();
    const fileName = item?.relationships?.field_media_file?.filemime;
    const fileType = cleanUpDocExcelFileTypes(fileName);
    const statusClean = `${item?.field_status
      .charAt(0)
      .toUpperCase()}${item?.field_status.slice(1)}`;
    const authorFullname = `${firstName} ${lastName}`;

    return {
      name: (
        <Link className={styles.documentLink} to={item?.path?.alias}>
          {item?.name}
        </Link>
      ),
      title: item?.name,
      created: item?.created,
      createdShort: item?.createdShort,
      // eslint-disable-next-line camelcase
      revised: item?.revision_created,
      // eslint-disable-next-line camelcase
      by: (
        <abbr title={authorFullname}>
          {(firstInitial && lastInitial) && (
            <>
              {firstInitial}.{lastInitial}.
            </>
          )}
        </abbr>
      ),
      authorName: authorFullname,
      uploadedBy: `${firstInitial}.${lastInitial}.`,
      status: statusClean,
      type: fileType,
      ref: (
        <Link className={styles.documentLink} to={item?.path?.alias}>
          {item?.relationships?.field_reference?.field_document_type_code}
          {item?.field_reference?.value}
        </Link>
      ),
      dept: item?.relationships?.field_department?.name || '--',
      download: (
        <Button
          aria-label={`Download ${item?.name}`}
          href={`${site?.siteMetadata?.drupalSiteURL}${item?.relationships?.field_media_file?.uri?.url}`}
          color="transparent"
        >
          <Download />
        </Button>
      ),
      url: item?.path?.alias,
      relationships: item?.relationships,
      email: item?.relationships?.uid?.name,
      drupal_id: item?.drupal_id
    };
  });

  return documents;
};
